import {
  animate,
  AnimatePresence,
  motion,
  useMotionValue,
} from 'framer-motion';
import React from 'react';
import { FocusScope } from 'react-aria';
import { Dialog, Modal } from 'react-aria-components';

import { IconButton } from './IconButton';
import { IconX } from './icons';
import { theme } from './theme';
import { useMediaQuery } from './utils';

interface HalfsheetProps {
  children: React.ReactNode;
  'aria-label': string;
  isOpen: boolean;
  onDismiss: () => void;
  shouldCloseOnInteractOutside?: (element: Element) => boolean;
  isDismissable?: boolean;
  id?: string;
}

const MotionModal = motion(Modal);

const staticTransition = {
  duration: 0.25,
  ease: [0.32, 0.72, 0, 1],
};

const inertiaTransition = {
  type: 'inertia' as const,
  bounceStiffness: 300,
  bounceDamping: 40,
  timeConstant: 300,
};

/**
 * A halfsheet is a modal that slides up from the bottom of the screen
 * on mobile and tablet devices, taking up half of the screen. On desktop
 * devices it does not render.
 */
export function Halfsheet(props: HalfsheetProps) {
  const isBelowDesktopMedia = useMediaQuery(
    theme.__futureMedia.below('desktop')
  );
  const onOpenChange = (isOpen: boolean) => {
    if (props.onDismiss && !isOpen) {
      props.onDismiss();
    }
  };

  let y = useMotionValue('50vh');

  React.useEffect(() => {
    if (!isBelowDesktopMedia) {
      onOpenChange(false);
    }
  }, [isBelowDesktopMedia, onOpenChange]);

  if (!isBelowDesktopMedia) {
    return null;
  }

  return (
    <AnimatePresence>
      {props.isOpen && (
        <MotionModal
          isOpen={true}
          onOpenChange={onOpenChange}
          shouldCloseOnInteractOutside={props.shouldCloseOnInteractOutside}
          isDismissable={true}
          className="hlx-halfsheet"
          initial={{ y: '100%', scale: 0.88 }}
          animate={{ y: 0, scale: 1 }}
          exit={{ y: '100%' }}
          transition={staticTransition}
          // @ts-expect-error
          id={props.id}
          style={{
            y,
          }}
          drag="y"
          dragConstraints={{
            top: 0,
            bottom: 0,
          }}
          dragElastic={{
            top: 0.05,
            bottom: 0.5,
          }}
          onDragEnd={(e, { offset, velocity }) => {
            if (offset.y > window.innerHeight * 0.5 || velocity.y > 10) {
              onOpenChange(false);
            } else {
              animate(y, '0%', {
                ...inertiaTransition,
                // @ts-expect-error
                min: 0,
                max: 0,
              });
            }
          }}
        >
          <FocusScope autoFocus={false} contain={true}>
            <Dialog
              aria-label={props['aria-label']}
              className="hlx-halfsheet-dialog"
            >
              {({ close }) => {
                return (
                  <>
                    <div className="hlx-halfsheet-header">
                      {props.isDismissable && (
                        <IconButton
                          size={'small'}
                          aria-label={'Dismiss'}
                          onPress={() => {
                            // Call click handler after capturing the click event.
                            // Otherwise, the modal will close before the event is
                            // processed, causing any buttons behind the modal at
                            // the same pointer position to be "clicked"
                            setTimeout(() => {
                              close();
                            }, 0);
                          }}
                          variant="transparent"
                        >
                          <IconX />
                        </IconButton>
                      )}
                    </div>
                    {props.children}
                  </>
                );
              }}
            </Dialog>
          </FocusScope>
        </MotionModal>
      )}
    </AnimatePresence>
  );
}

interface HalfsheetFooterProps {
  children: React.ReactNode;
}

export function HalfsheetFooter({ children }: HalfsheetFooterProps) {
  return <div className="hlx-halfsheet-footer">{children}</div>;
}
