import { MapPin } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconMapPin: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined']}
    defaultVariant="outlined"
    phosphorIcon={MapPin}
    ref={ref}
  />
));

IconMapPin.variants = ['outlined'];
IconMapPin.defaultVariant = 'outlined';
